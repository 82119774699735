<template>
  <div>
    <SkipToContent />

    <header class="py-6 grid place-items-center">
      <NuxtLink :to="localePath('/')"><img src="~assets/logo/logo.svg" alt="Tarvikeskus" class="h-6 dark:invert"></NuxtLink>
    </header>
    <main id="main-content">
      <NuxtPage />
    </main>

    <ToastNotifications />
  </div>
</template>

<script setup lang="ts">
const localePath = useLocalePath()

const { locale } = useI18n()
const config = useRuntimeConfig()
useSeoMeta({
  titleTemplate: '%s | Tarvike.com',
  title: 'Laatutuotteet arkeen ja vapaalle',
  ogTitle: 'Laatutuotteet arkeen ja vapaalle',
  ogSiteName: 'Tarvike.com',
  ogUrl: config.public.BASE_URL,
  robots: 'INDEX,FOLLOW',
  ogImage: `${config.public.BASE_URL}/images/og/tarvike-og-image.png`,
  twitterImage: `${config.public.BASE_URL}/images/og/tarvike-og-image.png`,
  description: 'Ajoneuvot maalle, tielle ja vesille, laitteita metsään ja puutarhaan, varusteet arkeen ja vapaalle, varaosat, tarvikkeet, huolto.',
  ogDescription: 'Ajoneuvot maalle, tielle ja vesille, laitteita metsään ja puutarhaan, varusteet arkeen ja vapaalle, varaosat, tarvikkeet, huolto.'
})
useHead({
  htmlAttrs: {
    lang: locale.value
  }
})
</script>
